
import { Faq } from '@monorepo-nx/std-watch/ui';

export function FaqsAccordion({name, faqs, href}) {
  return (
    <div>
      <h2 className="text-xl font-bold leading-10 tracking-tight text-gray-900">{name}</h2>
      <dl className="mt-4 space-y-6 divide-y divide-gray-900/10">
        {faqs.map((faq) => (
          <Faq question={faq.question} answer={faq.answer} href={href} topic={name}/>
        ))}
      </dl>
    </div>
  );
}
export default FaqsAccordion;
