import { HomeIcon } from '@heroicons/react/20/solid'
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { NavBar, Breadcrumbs, BreadcrumbItem, Disclosure, SubMenu } from '@monorepo-nx/std-watch/ui';


const Header = ({showSearchBar, showBreadcrumbs, mainMenu, logo, logoMobile}) => {

  const router = useRouter();
  const [breadcrumbs, setBreadcrumbs] = useState();

  function makeTitle(slug) {
    let words = slug.split(/[-_]/);
    for (let i = 0; i < words.length; i++) {
      let word = words[i];
      words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }

    return words.join(' ');
  }
  useEffect(() => {

    const pathWithoutQuery = router.asPath.split(/(\?|#|page)/)[0];
    let pathArray = pathWithoutQuery.split("/");
    pathArray.shift();

    pathArray = pathArray.filter((path) => path !== "");

    const breadcrumbs = pathArray.map((path, index) => {
      const href = "/" + pathArray.slice(0, index + 1).join("/");
      const label = makeTitle(path);
      return {
        href,
        label,
        isCurrent: index === pathArray.length - 1,
      };
    });

    setBreadcrumbs(breadcrumbs);
  }, [router.asPath]);

  return (
    <>
   
      <Disclosure />
      <div className="z-40 sm:pb-0 sticky top-0 bg-white  min-h-full border-b">
        <div className="sm:px-4 lg:px-8 xl:pb-4 lg:pb-2">
          <NavBar menuData={mainMenu} showSearchBar={showSearchBar} logo={logo} logoMobile={logoMobile}/>
        </div>
        
          {
            (
              router.pathname === "/[categorySlug]" ||
              router.pathname === "/[categorySlug]/page/[page]" ||
              router.pathname === "/[categorySlug]/[subcategorySlug]" ||
              router.pathname === "/[categorySlug]/[subcategorySlug]/page/[page]"
            ) &&
            <div className=" ">
            <SubMenu />
            </div>
          }    
      </div>

      <div className="max-w-8xl lg:mx-auto md:px-8">
        {router.pathname !== "/" && showBreadcrumbs &&
          <Breadcrumbs>
            <BreadcrumbItem  href="/">
              <HomeIcon className="flex-shrink-0 h-3 w-3 font-thin text-gray-600" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </BreadcrumbItem>
            {breadcrumbs &&
              breadcrumbs.map((breadcrumb, index) => (
                <BreadcrumbItem
                  key={index}
                  href={breadcrumb.href}
                  isCurrent={breadcrumb.isCurrent}>
                  {breadcrumb.label}
                </BreadcrumbItem>
              ))}
          </Breadcrumbs>}
      </div>
   
    </>
  );
};


export default Header;


