import React from 'react';
import Link from 'next/link';
interface Post {
  title: string;
  image: string;
  slug: string;
  author: { name: string; picture: { url: string } };
  excerpt: string;
  date: Date;
}
const SearchPostCard = (post: Post) => (
  <div className="relative max-w-10xl mx-auto">
    <h1 className="transition duration-700 mx-8 my-4 cursor-pointer  ">
      <Link href={`/blog/${post.slug}`}>{post.title}</Link>
    </h1>
  </div>
);

export default SearchPostCard;
