import { HorizontalCards, PostCard } from '@monorepo-nx/std-watch/ui';
const SHOW_PER_PAGE = 19;

interface PaginationProp {
    total: number
    currentPage: number
    prefix: string
}


const PostsGridLayout = ({ posts }) => {

    return (
        <div className='grid grid-flow-row grid-cols-1 lg:grid-cols-3 lg:grid-rows-6 gap-1'>

            {posts
                .map((post, id) => (
                    <div  key={id} className="full-width">
                        <PostCard
                            post={post.node ? post.node : post}
                            section={'postPreviewList'}
                        />
                    </div>
                ))}
        </div>
    )
}
export default PostsGridLayout