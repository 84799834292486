import React from 'react';
import Link from 'next/link';

const QuickNav = ({ title, slug, backgroundImage }) => (
  <div className="relative h-40 w-40">
    <div className="absolute rounded-lg bg-center bg-no-repeat bg-cover shadow-md inline-block w-40 h-40" style={{ backgroundImage: `url('${backgroundImage}')` }} />
    <div className="absolute rounded-lg bg-center bg-gradient-to-b opacity-50 from-gray-300 via-gray-600 to-black w-40 h-40" /> {/* from-gray-400 via-gray-700 to-black */}
    <div className="flex flex-col rounded-lg p-4 items-center justify-center absolute w-full h-full">
      <p className="text-white mb-4 text-shadow font-semibold text-2xl text-center">{title}</p>
    </div>
    <Link href={`/blog/${slug}`}><span className="cursor-pointer absolute w-full h-full" /></Link>
  </div>
);

export default QuickNav;