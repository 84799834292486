import React, { useEffect, useState } from 'react'
import * as Sentry from "@sentry/nextjs";
import { SendEvent, SendUniqueClickout } from '@monorepo-nx/std-watch/services';
import Link from 'next/link';

const sendClickout = (e, component, linkText, href, section, advertiser) => {
  component = e.target.tagName
  linkText = linkText ? linkText :  e.target.innerText
 
  SendEvent({ eventName: 'clickout', component, text: linkText, href, section})
  SendUniqueClickout({Advertiser: advertiser, PagePath: window.location.pathname, Element: component, Text: ""})
}

export function AffiliateLink({ referralLink, buttonClassName, children, linkText, affName, section, advertiser, ...props }) {
  return (
    
    <Link {...props} href={referralLink} target={"_blank"} className="aff-link bold no-underline" rel="noopener" onClick={e => sendClickout(e, e.target, linkText, affName, section, advertiser)}>
      { buttonClassName ?
        <button className={buttonClassName}>
            {children}
        </button> 
         : children}
    </Link>
  )
}

export default AffiliateLink;
