import ChartSingleRow from "../chart-single-row/chart-single-row";


export function DefaultChart({chart, siteConfig}) {
  return (
    <>
      {chart?.promotions?.map((promotion,index) => (
        <div key={index} className="py-5">
          <ChartSingleRow promotion={promotion} callToAction={chart.callToAction ?? promotion.callToAction} position={index} section="chart" colorPalette={siteConfig?.colorPalette} domain={siteConfig?.domain} showNumber={true}/>
        </div>
      ))}
    </>
  );
}
export default DefaultChart;
