import { useEffect, useState } from 'react'
import { ArrowUpCircleIcon } from '@heroicons/react/20/solid';


const NUM_OF_SCREENS_OFFSETS = 3

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}
export const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false)

  const toggleVisibility = (NUM_OF_SCREENS_OFFSETS) => {
    const screenHeight = document.documentElement.clientHeight
    if (window.pageYOffset > screenHeight * NUM_OF_SCREENS_OFFSETS) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
     // Scroll into view after user scrolls 3 screens
    window.addEventListener('scroll', () => toggleVisibility(NUM_OF_SCREENS_OFFSETS))

    return () => {
      window.removeEventListener('scroll', toggleVisibility)
    }
  }, [])

  return (
    <div className="fixed bottom-8 right-8 z-50">
      <button
        id="scroll-to-top"
        type="button"
        onClick={scrollToTop}
        className={classNames(
          isVisible ? 'opacity-100' : 'opacity-0',
          'bg-neutral-100 inline-flex text-lg rounded-3xl  p-2 pl-3 shadow-md ',
        )}
      >
     
        Back to Top
        <ArrowUpCircleIcon className="h-6 w-6 mx-1 mt-0.5" aria-hidden="true" />
        </button>
    </div>
  )
}

export default ScrollToTop;

