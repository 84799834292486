import clsx from 'clsx'

export function SectionHeading({ number, children, className, ...props }) {
  return (
    <button
      className={clsx(
        className,
        'inline-flex items-center rounded-full  sm:ml-8 py-1 px-4  bg-indigo-50 text-indigo-600 ring-1 ring-inset ring-indigo-600'
      )}
      {...props}
    >
      <span className="font-mono text-sm" aria-hidden="true">
        {number.padStart(2, '0')}
      </span>
      <span className="ml-3 h-3.5 w-px bg-blue-600/20" />
      <span className="ml-3 font-medium tracking-tight">
        {children}
      </span>
    </button>
  )
}
export default SectionHeading;
