import { Footer, Header, ScrollToTop } from '@monorepo-nx/std-watch/ui';
import { DefaultSeo } from 'next-seo';
import SEO from './next-seo.config';


const Layout = ({ children, mainMenu, showSearchBar=true, showBreadcrumbs=true, addDefaultSeo=true }) => (
    <>
        {addDefaultSeo && <DefaultSeo {...SEO} />} 
        <Header mainMenu={mainMenu} showSearchBar={showSearchBar} showBreadcrumbs={showBreadcrumbs}/>
        <ScrollToTop />
        {children}
        <Footer/>
    </>
);

export default Layout;
