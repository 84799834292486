import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'





export function ContactSection(props) {
  const [open, setOpen] = useState(false)

  return (
    <>

      <div onClick={() => setOpen(true)}>
        <svg className="w-5 h-5  hover:text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z">
          </path>
        </svg>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed  z-10 inset-0 ">
            <div className="flex items-center justify-center min-h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg p-4 text-left overflow-hidden shadow-xl transform transition-all sm:max-w-sm sm:w-full ">
                  <div>
                    <div className="text-right">
                      <button
                        type="button"
                        className="bg-white rounded-md  inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setOpen(false)}>
                        <XMarkIcon className=" h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>

                    <div className="relative">
                      <div className="absolute inset-0">
                        <h3 className="text-center title-fon text-gray-900">Contact Me</h3>
                      </div>
                      <div className="relative max-w-8xl mx-auto ">
                        <div className="px-2 pt-8 ">
                          <div className="max-w-lg mx-auto lg:max-w-none">
                            <form action="#" method="POST" className="grid grid-cols-1 gap-y-2">
                              <div>
                                <label htmlFor="full-name" className="sr-only">Name</label>
                                <input
                                  type="text"
                                  name="full-name"
                                  id="full-name"
                                  autoComplete="name"
                                  className="block w-full shadow-sm py-1 md:py-2 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                  placeholder="Name"
                                />
                              </div>
                              <div>
                                <label htmlFor="email" className="sr-only">Email</label>
                                <input
                                  id="email"
                                  name="email"
                                  type="email"
                                  autoComplete="email"
                                  className="block w-full shadow-sm py-1 md:py-2 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                  placeholder="Email"
                                />
                              </div>

                              <div>
                                <label htmlFor="message" className="sr-only">Message</label>
                                <textarea
                                  id="message"
                                  name="message"
                                  rows={3}
                                  className="block w-full shadow-sm py-1 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                                  placeholder="Message"
                                  defaultValue={''}
                                />
                              </div>
                              <div className="sm:py-4">
                                <button
                                  type="submit"
                                  className="flex mx-auto text-white bg-indigo-500 border-0  px-4 focus:outline-none hover:bg-indigo-600 rounded text-lg">Submit
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
export default ContactSection;
