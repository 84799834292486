import Link from 'next/link'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { SendEvent } from '@monorepo-nx/std-watch/services';

const handleClick = (e, heading) => {
  SendEvent({eventName: 'click-component', component: 'TOC', text: heading.title})
  e.preventDefault();
  document.querySelector(`#${heading.id}`).scrollIntoView({
    behavior: "smooth"
  });
}

const Headings = ({ headings, activeId }) => (


  <Menu as="div" className="relative block z-20">
    <span className='hidden lg:block font-bold'>
      On this page</span>
    <ul className="hidden lg:block pb-8">
      {headings.map((heading, index) => (
        <li key={heading.id} className={heading.id === activeId ? "text-indigo-600" : ""}>
          <div className="relative flow-root  ">
            <div className="text-sm   flex my-[6px]">
              <div className="relative grid  justify-items-center  ">
                {index !== headings.length - 1 ? (
                  <span className="absolute  top-5 h-full w-0.5 bg-gray-300" />
                ) : null}
                <div className={heading.id === activeId ? "mt-2  h-3 w-3 rounded-full border-2 border-indigo-600  " : "mt-2  h-3 w-3 rounded-full border-2 border-gray-300  "} />
              </div>
              <div className="w-full px-4">
                {/* NOTE: Use the "a" tag here to avoid Link's behaviour of adding the relative url to the href property, which messed up Google SERP */}
                <a
                  href={`#${heading.id}`}
                  onClick={(e) => handleClick(e, heading)}
                >
                  {heading.title}
                </a>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>

    
    <Menu.Button className="lg:hidden border border-transparent w-screen  py-2 px-10 inline-flex  items-center justify-end  bg-white delay-100 duration-100  font-bold -mr-2">
      <span>
        On this page</span>
      <ChevronDownIcon className="h-5 w-5" />
    </Menu.Button>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className=" h-[400px] border border-transparent overflow-y-scroll origin-top-right absolute rounded-sm right-0 w-2/3 shadow-lg bg-gray-50">
        <div className=" h-fit ">
          {headings.map((heading, index) => (
            <Menu key={heading.id} >
              <div className={heading.id === activeId ? "text-indigo-500" : ""}>
                <div className="text-sm  pl-4 flex my-[9px]">
                  <div className="relative grid  justify-items-center  ">
                    {index !== headings.length - 1 ? (
                      <span className="absolute  top-5   h-full w-0.5 bg-gray-300" />
                    ) : null}
                    <div className={heading.id === activeId ? "mt-2  h-3 w-3 rounded-full border-2 border-indigo-600  " : "mt-2  h-3 w-3 rounded-full border-2 border-gray-300  "} />

                  </div>
                  <div className=" w-full m-1 px-4">
                    <Link
                      href={`#${heading.id}`}
                      onClick={(e) => handleClick(e, heading)}
                    >
                      {heading.title}
                    </Link>
                  </div>
                </div>
              </div>
            </Menu>
          ))}
        </div>
      </Menu.Items>
    </Transition>
  </Menu>
);
export default Headings;


