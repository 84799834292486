import { renderRTF } from '@monorepo-nx/std-watch/ui';
const endpoint = process.env["NEXT_PUBLIC_GRAPHQL_API"]

const PostDetail = ({ post }) => {
  if (!post.content) return "No Content"

  return (
        <article className="prose md:text-lg prose-a:text-indigo-500 hover:prose-a:text-indigo-600">
        {renderRTF(post.content.json, post.content.references, endpoint) }
        </article>
        )
};

export default PostDetail;

