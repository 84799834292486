import Image from "next/image";

interface ImageWithCaptionProps {
    src: string;
    alt: string;
    title: string;
    width: number;
    height: number;
    loading?: "lazy" | "eager" | undefined;
    className?: string;
}

function ImageWithCaption({ src, alt, title, width, height, loading = "lazy", className }: ImageWithCaptionProps) {
    return (
        <figure>
            <Image
                width={width}
                height={height}
                loading={loading}
                placeholder={loading === "eager" ? "empty" : "blur"}
                alt={alt}
                blurDataURL="https://media.graphassets.com/AujNZF9cR2qpLAF4Nx1p"
                src={src}
                className={className}
                title={title}
                property="image"
            />
            <figcaption className="text-sm">
                <section>{alt}</section>
                <section></section>
            </figcaption>
        </figure>
    );
}

export default ImageWithCaption;