import moment from 'moment';
import { grpahCMSImageLoader } from '@monorepo-nx/std-watch/services';
import Image from 'next/image'
import Link from 'next/link';
import {SendEvent} from '@monorepo-nx/std-watch/services';
import clsx from 'clsx';
const handleClick = (e, linkText, href, section) => {
  SendEvent({eventName: 'click-component', component: 'horizontal-card', href, section, text: linkText})
}

export function HorizontalCards({ post, section, showExcerpt = true}) {
  const href = "/blog/" + post.slug;

  return (
    <div className="my-6  sm:px-4 flex flex-col items-center justify-center cursor-pointer md:hover:scale-105 ease-in-out duration-300 ">
      <div className="flex flex-col  ">
      <Link legacyBehavior  href={href} >
        <div className="grid sm:grid-cols-4 gap-2 "onClick={(e) => handleClick(e, post.title, href, section)} >
          <div className="sm:col-span-3 flex flex-col text-gray-700  ">
              <div className="font-bold text-lg text-gray-700 text-left pt-3 ">
                {post.title}
              </div>

            <div className="font-light text-sm lg:pt-2 lg:pb-4">
              {showExcerpt ? post.excerpt: ''}
            </div>
            <div className="flex flex-row pt-4 xl:pt-0 gap-3 items-center ">
              <div className="pl-2 text-sm text-gray-500"> Updated at {moment(post.updatedAt).format('MMM DD, YYYY')}</div>
              <Link href={post.author ? "/medical-team/" + post.author.slug : ''}>
                <p className="text-sm text-gray-500  hover:text-gray-900 "> {post.author ? post.author.name : ''}</p>
              </Link>
            </div>
            <div className="flex flex-row pt-4 gap-3 items-center ">
              <div className="bg-gray-200 rounded-full py-1 px-2 text-xs">
                {post.category?.replaceAll('_', ' ')}
              </div>
              <div className="bg-gray-200 rounded-full py-1 px-2 text-xs">
                {post.subCategory?.replaceAll('_', ' ')}
              </div>
              <div className="text-gray-500 text-xs">
                3 min read
              </div>
            </div>
          </div>
          <div className="hidden sm:flex items-center ">
            <Link href={href} onClick={(e) => handleClick(e, post.coverImage?.url, href, section)}>
              <Image className=" object-cover shadow-lg rounded-2xl"
                width={200}
                height={150}
                loading="lazy"
                placeholder="blur"
                blurDataURL="https://media.graphassets.com/AujNZF9cR2qpLAF4Nx1p"
                src={post.coverImage ? post.coverImage.url : " https://media.graphassets.com/TU7xL6CRRzyS8ghMrhlx"}
                alt={post.coverImage?.altText}/>
            </Link>
          </div>
         </div>
         </Link>
        <hr className="mt-5"></hr>
      </div>
    </div>
  )
};
export default HorizontalCards;
