import { CheckIcon } from '@heroicons/react/20/solid';
import React from 'react';
import Image from 'next/image';


export function HeroSection({title, subtitle, bgImage}) {
  return (

    <header className="mb-20 lg:mb-24 ">
        <div className="relative w-full sm:h-70 flex items-center justify-center">
          {bgImage && (
            <div className=" inset-0 z-0">
              <Image
                src={bgImage.url}
                alt="Background image"
                layout="fill"
                objectFit="cover"
                quality={75}
                className="bg-center"
                priority
              />
            </div>
          )}

            
            <div className="z-10 flex items-center justify-center w-full h-full bg-gray-500 bg-opacity-50  py-6 lg:py-12">
            <div className="text-center">
                <div className="container px-4 mx-auto">
                <div className="max-w-4xl mx-auto text-center">
                    <h1 className="mt-2 mb-6 text-3xl lg:text-5xl lg:font-bold text-gray-100">{title}</h1>
                    <h2 className="hidden md:block max-w-3xl mx-auto mb-3 lg:mb-6 text-m lg:text-xl lg:font-bold text-gray-100">
                    {subtitle}
                    </h2>
                    <p className="max-w-4xl flex items-center justify-center  w-full mb-10 lg:mb-10 text-s lg:text-m text-gray-100">
                    <CheckIcon className="mx-2 h-6 w-6 text-lime-300 font-bold"  />
                    Updated {new Date().toLocaleString('default', {month: 'long'})} {new Date().getFullYear()}
                    </p>
                </div>
                </div>
            </div>
            </div>
        </div>
    </header>
  );
}
export default HeroSection;
