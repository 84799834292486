import Button from '../button/button';
import ImageWithCaption from '../image/image';
import AffiliateLinkContainer from '../affiliate-link/affiliate-link-container';
import Rating from '../../components/rating';

interface ProductReviewProps {
    id: string
    position: number | undefined;
    name: string;
    productLink: any;
    brand: any;
    productScore: number;
    bestRating?: number;
    reviewAuthor: string;
    description: string;
    pros: string;
    cons: string;
    image: ImageProps;
}
interface ImageProps {
    url: string;
    width: number;
    height: number;
    altText: string;
}

function ProductReview(product: ProductReviewProps) {
    const bestRating = product.bestRating ?? 5;
    return (
        <div id={product.id} vocab="https://schema.org/" typeof="Product"
            // id="section0"// commented out as it may be irrelevant    
            key={product.id} className="group relative  p-4 sm:p-6">
            <div><p>{product.position ?? ""}</p></div>
            <h2 className="text-xxl text-gray-900 text-center">
                <AffiliateLinkContainer section="product-review" affLink={product.productLink?.affiliate_link} affName={product.productLink.name} dataAdvertiser={product.brand.name} siteConfig={{}} endpoint={""}>
                    {product.name}
                </AffiliateLinkContainer>
            </h2>
            <div className='grid grid-flow-row grid-cols-5 lg md:grid-rows-2 gap-1'>
                <div className="row-span-2 col-span-2 aspect-h-1 aspect-w-1 overflow-hidden rounded-lg group-hover:opacity-75">
                    <AffiliateLinkContainer section="product-review" affLink={product.productLink?.affiliate_link} affName={product.productLink.name} dataAdvertiser={product.brand.name} property="name" siteConfig={{}} endpoint={""}>
                        <ImageWithCaption src={product.image?.url} alt={product.name} title={product.name} width={product.image?.width} height={product.image?.height} className="h-full w-full object-cover object-center" />
                    </AffiliateLinkContainer>
                </div>
                <div className="pb-4 pt-5 pl-10 col-span-3 text-md md:text-lg">
                    <div className="row-span-1">
                        <div className="flex flex-col space-y-3">

                            <div className="text-sm">
                                <Rating size={4}>
                                    <span className="pl-3">
                                        {product.productScore}
                                    </span>
                                </Rating>
                            </div>
                            {/* <div>{product.reviewAuthor}</div> */}
                        </div>
                    </div>
                    <div className="pt-5">
                        <div className="font-bold">Pros</div>
                        <div>{product.pros}</div>
                    </div>
                    <div>
                        <div>
                            <div className="font-bold">Cons</div>
                            <div>{product.cons}</div>
                        </div>
                    </div>
                </div>
                <div className="col-span-5 md:col-span-3 text-center">
                    <AffiliateLinkContainer
                        className="text-lg"
                        section="product-review"
                        affLink={product.productLink?.affiliate_link}
                        affName={product.productLink.name}
                        dataAdvertiser={product.brand.name}
                        siteConfig={{}}
                        endpoint={""}>
                        <Button>View on {product.brand.domain}</Button>
                    </AffiliateLinkContainer>
                </div>
            </div>
            <div>
                <p property="description">{product.description}</p>
                <div property="review" typeof="Review" style={{ display: 'none' }}>
                    <div property="reviewRating" typeof="Rating">Rated <span property="ratingValue">{product.productScore}</span> based on <span property="bestRating">{bestRating}</span></div>
                    <div property="author" typeof="Person"><span property="name">STDWatch</span></div>
                </div>
            </div>
        </div >
    );
}

export default ProductReview;
