import React from 'react';

export const AccordionCollapse = ({ button }) => {

  return (
        <Accordion className="">

          <AccordionItem toggle="panel-1">
            {button.NavMenuDropdown_title_alias}
          </AccordionItem>

          <AccordionPanel id="panel-1" className="overflow-scroll min-h-60">
            <div className="relative grid grid-cols-1  bg-white px-5 py-2  sm:p-2 overflow-scroll h-48">
              <ul className="">
                {button.NavMenuDropdown_links_alias?.map((dropdown, index) => (
                  dropdown.isColored ?
                    <li key={index}>
                      <a href={dropdown.url} className="flex items-center p-1 font-medium text-indigo-600 hover:text-indigo-500">
                        {' '}
                        {dropdown.title} <span aria-hidden="true">  &rarr;</span>
                      </a>
                    </li> :
                    <li key={index} className="">
                      <a href={dropdown.url} className="flex items-center p-1 rounded-lg font-medium min-h-30 text-base text-gray-900 hover:text-gray-700 overflow-scroll truncate  hover:bg-gray-50 ">
                        {dropdown.title}
                      </a>
                    </li>
                ))}
              </ul>
            </div>
          </AccordionPanel>
        </Accordion>
  )
};



/* Logic */
const Context = React.createContext({});

function Accordion({ children }) {
  const [selected, setSelected] = React.useState();

  const toggleItem = React.useCallback(
    (id) => () => {
      setSelected((prevState) => (prevState !== id ? id : ''));
    },
    [],
  );
  return (
    <Context.Provider value={{ selected, toggleItem }}>
      {children}
    </Context.Provider>
  );
}

//custom hook to consume all accordion values
const useAccordion = () => React.useContext(Context);
const style = {
  item: `rounded-md border border-gray-300 shadow-sm  px-4 py-2 bg-white  text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 `,
  panel: `overflow-hidden md:overflow-x-hidden transition-height ease duration-300 text-gray-600`,
};

function AccordionItem({ toggle, children, className }) {
  const { selected, toggleItem } = useAccordion();
  return (
    <button

      onClick={toggleItem(toggle)}
      className={`${style.item} ${className}`}
    >
      {children}
      <span className="float-right">
        {selected === toggle ? <AngleUpIcon /> : <AngleDownIcon />}
      </span>
    </button>
  );
}



function AccordionPanel({ children, id }) {
  const { selected } = useAccordion();
  const ref = React.useRef();
  const inlineStyle =
    selected === id ? { height: ref.current?.scrollHeight } : { height: 0 };

  return (
    <div ref={ref} id={id} className={style.panel} style={inlineStyle}>
      {children}
    </div>
  );
}

const AngleUpIcon = () => (
  <svg
    fill="gray"
    strokeWidth="0"
    viewBox="0 0 320 512"
    xmlns="http://www.w3.org/2000/svg"
    className="mt-1 h-4"
  >
    <path d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z" />
  </svg>
);

const AngleDownIcon = () => (
  <svg
    stroke="currentColor"
    fill="gray"
    strokeWidth="0"
    viewBox="0 0 320 512"
    xmlns="http://www.w3.org/2000/svg"
    className="mt-1 h-4"
  >
    <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
  </svg>
);
export default AccordionCollapse;
