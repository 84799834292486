import { RichText } from '@graphcms/rich-text-react-renderer';

export default function Callout({ icon, children, className }) {
    return (
        <div className={`rounded-md ${className}`}>
            <div className="flex">
                <div className="flex-shrink-0">
                    {icon}
                </div>
                <div className="ml-3">
                    <RichText content={children} />
                </div>
            </div>
        </div>
    )
}