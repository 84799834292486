import { useEffect, useState, useRef } from 'react';
import {Headings} from '@monorepo-nx/std-watch/ui'
import { useRouter } from 'next/router';

const useHeadingsData = () => {
  const router = useRouter();
    const [nestedHeadings, setNestedHeadings] = useState([]);

    useEffect(() => {
      const headingElements = Array.from(
        document.querySelectorAll('article > h2') //add h3 if needed
        
      );

      const newNestedHeadings = getNestedHeadings(headingElements);
      setNestedHeadings(newNestedHeadings);
    }, [router.asPath]);

    return { nestedHeadings };
  };

  const getNestedHeadings = (headingElements) => {
    const nestedHeadings = [];

    headingElements.forEach((heading, index) => {
      const { innerText: title, id } = heading;

      if (heading.nodeName === "H2") {
        nestedHeadings.push({ id, title, items: [] });
      } else if (heading.nodeName === "H3" && nestedHeadings.length > 0) {
        nestedHeadings[nestedHeadings.length - 1].items.push({
          id,
          title,
        });
      }
    });
    return nestedHeadings;
  }
  const useIntersectionObserver = (setActiveId) => {
    const router = useRouter();
    const headingElementsRef = useRef({});
    useEffect(() => {
      const callback = (headings) => {
        headingElementsRef.current = headings.reduce((map, headingElement) => {
          map[headingElement.target.id] = headingElement;
          return map;
        }, headingElementsRef.current);
  
        const visibleHeadings = [];
        Object.keys(headingElementsRef.current).forEach((key) => {
          const headingElement = headingElementsRef.current[key];
          if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
        });
  
        const getIndexFromId = (id) =>
          headingElements.findIndex((heading) => heading.id === id);
  
        if (visibleHeadings.length === 1) {
          setActiveId(visibleHeadings[0].target.id);
        } else if (visibleHeadings.length > 1) {
          const sortedVisibleHeadings = visibleHeadings.sort(
            (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
          );
          setActiveId(sortedVisibleHeadings[0].target.id);
        }
      };
  
      const observer = new IntersectionObserver(callback, {
        rootMargin: "0px 0px -40% 0px"
      });
      
      const headingElements = Array.from(document.querySelectorAll("h2")); //add h3 if needed
  
      headingElements.forEach((element) => observer.observe(element));
  
      return () => observer.disconnect();
    }, [setActiveId, router.query]);
  };
const  TableOfContents = () => {
  const [activeId, setActiveId] = useState();
      const { nestedHeadings } = useHeadingsData();
      useIntersectionObserver(setActiveId);
    return (
      <nav className="lg:max-h-[70vh] xl:max-h-[80vh] 2xl:max-h-[85vh] lg:overflow-y-scroll lg:overflow-x-hidden"> 
        <Headings headings={nestedHeadings} activeId={activeId}/>
      </nav>
    );
  };
export default TableOfContents;