// https://blog.anishde.dev/making-an-accessible-breadcrumb-navigation-using-tailwindcss-and-nextjs#heading-building-the-breadcrumb-navigation
// https://github.com/AnishDe12020/nextjs-breadcrumb-example/blob/main/pages/_app.js
import { Children } from "react";
import { Fragment } from "react";

const Breadcrumbs = ({ children }) => {
  const childrenArray = Children.toArray(children);

  const childrenWtihSeperator = childrenArray.map((child, index) => {
    if (index !== childrenArray.length - 1) {
      return (
        <Fragment key={index}>
          {child}
          <svg
                className="flex-shrink-0  h-4 w-4  text-gray-500  "
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
               <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
              </svg>
        </Fragment>
      );
    }
    return child;
  });

  return (
    <nav className="hidden md:flex my-1  " aria-label="breadcrumb">
      <ol className="flex items-center  space-x-2">{childrenWtihSeperator}</ol>
    </nav>
  );
};

export default Breadcrumbs;