
import moment from 'moment';
import { grpahCMSImageLoader, slugify } from '@monorepo-nx/std-watch/services';
import Image from 'next/image'
import Link from 'next/link';
import { SendEvent } from '@monorepo-nx/std-watch/services';

const handleClick = (e, value, section) => {
    SendEvent('click-component', 'horizontal-card', value, section)
}

export function FeatureArticle2({ post, section, category}) {
    const categorySlug = slugify(category);

    return (

        // <div class="min-h-screen bg-gray-50 flex flex-col justify-center relative overflow-hidden sm:py-12">
        <div className="max-w-8xl cursor-pointer hover:scale-105 ease-in-out duration-300 ">
            <div className="relative group">
                <div className="absolute -inset-0.5 bg-indigo-100 rounded-lg blur  transition duration-1000 group-hover:duration-200"></div>
                <div className="relative  bg-white ring-1 ring-gray-900/5 rounded-lg leading-none flex items-top justify-start ">
                    <div className="">
                    <Link  href={`/${categorySlug}/${post?.slug}`} legacyBehavior>
                            <div
                                onClick={(e) => handleClick(e, post?.slug, section)}
                                className="my-6  sm:px-4 flex flex-col items-center justify-center ">
                                <div className="flex flex-col  ">
                                    <div className="grid sm:grid-cols-4 gap-1 ">
                                        <div className="m-3 sm:col-span-4 md:col-span-2 flex flex-col text-gray-700 hover:text-black ">

                                            <div className="font-bold text-lg text-center pt-3 ">
                                                {post?.title}
                                            </div>
                                            <div className="md:hidden my-2 items-cente ">
                                                <Image className=" object-cover rounded-2xl"
                                                    loader={grpahCMSImageLoader}
                                                    width="624"
                                                    height="420"
                                                    loading="lazy"
                                                    placeholder="blur"
                                                    blurDataURL="https://media.graphassets.com/AujNZF9cR2qpLAF4Nx1p"
                                                    src={post?.coverImage ? post.coverImage.url : " https://media.graphassets.com/TU7xL6CRRzyS8ghMrhlx"}
                                                    alt="post-cover" />
                                            </div>
                                            <div className="font-light text-sm lg:pt-2 lg:pb-4">
                                                {post?.excerpt}
                                            </div>
                                            <div className="flex flex-row pt-4 xl:pt-0 gap-3 items-center ">
                                                <div className="pl-2 text-sm text-gray-500"> {moment(post?.createdAt).format('MMM DD, YYYY')}</div>
                                                <Link  href={post?.author ? "/medical-team/" + post?.author.slug : ''}>
                                                    <p className="text-sm text-gray-500  hover:text-gray-900 "> {post?.author ? post?.author.name : ''}</p>
                                                </Link>
                                            </div>
                                            <div className="flex flex-row pt-4 gap-3 items-center ">
                                                <div className="  bg-gray-200 rounded-full py-1 px-2 text-xs">
                                                    {post?.subcategory ? post.subcategory.title : " "}
                                                </div>
                                                <div className="text-gray-500 text-xs">
                                                    7 min read
                                                </div>
                                            </div>
                                        </div>
                                        <div className="hidden md:flex items-center md:col-span-2">
                                            <Image className=" object-cover shadow-lg rounded-2xl"
                                                loader={grpahCMSImageLoader}
                                                width="300"
                                                height="200"
                                                loading="lazy"
                                                placeholder="blur"
                                                blurDataURL="https://media.graphassets.com/AujNZF9cR2qpLAF4Nx1p"
                                                src={post?.coverImage ? post.coverImage.url : " https://media.graphassets.com/TU7xL6CRRzyS8ghMrhlx"}
                                                alt="post-cover " />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Link>



                    </div>
                </div>
            </div>
        </div>
        // </div>

    );
}

export default FeatureArticle2;