import {AuthorsTeam,  ChartTop3, FeatureArticle, PostsGridLayout, Pagination} from '@monorepo-nx/std-watch/ui';


const disclaimer = "The information provided herein does not constitute an expert or medical advice, nor intended to replace such advice."
const CategoryPage = ({title, subtitle, posts, authors, pagination, disclaimer}) => {
 

  let totalPosts = [];
  if (!typeof posts === 'array' || posts.length < 1) {
    return <h3>no Data</h3>;
  }
  
  const featuredPost = posts[0]

  return (
    <div className=" container mx-auto">
      <div className="sm:grid grid-cols-8">
        <aside className="hidden sticky top-[100px] h-fit ml-7 col-span-1">
          {/* <SubcategoryButtons categoryPage={categoryPage} /> */}

          <div className="hidden lg:block my-6 mx-10 xl:mx-0">
            <ChartTop3 />
            <AuthorsTeam authors={authors} />
          </div>
        </aside>
      {/* Main Column  */}
        <div className="sm:ml-8 mt-4 md:mt-0 col-span-8 sm:col-span-8 lg:col-span-6 px-4 lg:px-12 sm:px-20 ">
          <h1 className="mb-2 text-center text-3xl ">{title}</h1>
          <h2 className="mb-2 text-center text-lg ">{subtitle}</h2>
          <section
            aria-labelledby="latest-posts-heading"
            className="relative text-center sm:text-left scroll-mt-16  sm:scroll-mt-58"
          >
            <p className=" text-xs font-thin text-left text-gray-600 mb-4 ">
              {disclaimer ? disclaimer.text :  disclaimer} 
            </p>
            
            <FeatureArticle
                key={"0-f"}
                scale={1.3}
                post={featuredPost.node ? featuredPost.node: featuredPost}
                section={'postPreviewList'}
              />
          
            <PostsGridLayout
              posts={posts.slice(1)}
             />
             <Pagination
                prefix={pagination.prefix}
                totalPostCount={pagination.total}
                pageSize={pagination.pageSize}
                currentPage={pagination.currentPage}
                firstPagePath={""}
            />
          </section>
          
        </div>

        <aside className="sticky top-[150px] h-fit md:mx-3 xl:mx-0 col-span-8 sm:col-span-8 md:col-span-8  lg:col-span-2">
          <ChartTop3 />
          <AuthorsTeam authors={authors} />
        </aside>
      </div>
    </div>
  );
};

export default CategoryPage;
