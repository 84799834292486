import Link from "next/link";

const BreadcrumbItem = ({ children, href, isCurrent, ...props }) => {
  return (
    <li {...props} className= " text-sm font-lightthin text-gray-500 hover:text-gray-900">
      <div className="flex items-center">
             
      <Link href={href} legacyBehavior
          aria-current={isCurrent ? "page" : "false"}
        className={isCurrent && "ml-1 text-xs "}><a >{children}</a>
      </Link>
      </div>
    </li>
  );
};

export default BreadcrumbItem;