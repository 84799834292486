import Link from 'next/link';
import moment from 'moment';
import { aaSendClickEvent, cdnLoader, getAuthorPageUrl } from '@monorepo-nx/std-watch/services';
import Image from 'next/image';

const SITE_HOSTNAME = process.env.HOSTNAME || 'https://www.stdwatch.com/'


const CardImage = ({hit})=>(
  
  <div className="flex-shrink-0">
    <Image
    src={hit.image} alt="" 
    loader={cdnLoader}
    withWebp={true}
    loading="lazy"
    className="h-48 w-full object-cover"
    width={400} 
    height={300}
    quality="85%"  />
  </div>
)
const CardHeader = ({hit})=>(
  <div className="flex-1">
    <p className="text-xl font-semibold text-gray-900">{hit.title}</p>
  </div>
)
const CardBody = ({hit})=>(
  <p className="mt-3 text-base text-gray-500">
    {hit.meta_description}
    <span style={({color: "blue"})}>  Read Post...</span>
    </p>
)
const CardFooter = ({hit})=>(
  <div className="mt-6 flex items-center">

    <div className="flex-shrink-0">
      {/* TODO: Replace with Author URL */}
      <a href={getAuthorPageUrl(hit.author.slug)}>
        <span className="sr-only">{hit.author ? hit.author.name : ''}</span>
        <img className="h-10 w-10 rounded-full" src={hit.author ? SITE_HOSTNAME + hit.author.picture : 'some/url'} alt={hit.author.name} />
      </a>
    </div>
    <div className="ml-3">
      <p className="text-sm font-medium text-gray-900">
        <a href={hit.author ? SITE_HOSTNAME + hit.author.url : "some/default/url"} className="hover:underline">
          {hit.author ? hit.author.name : ''}
        </a>
      </p>
      <div className="flex space-x-1 text-sm text-gray-500">
        <span>{moment(hit.date).format('MMM DD, YYYY')}</span>
      </div>
    </div>
  </div>
)
  
export function ThreeColumnCards({ hits }) {

  return (
    <div className="relative max-w-10xl mx-auto">
        <div className="mt-12 max-w-lg mx-auto grid gap-8 lg:grid-cols-3 lg:max-w-none">
            {hits.map((hit) => (
                <div key={hit.title} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                    <CardImage hit={hit} />
                    <div className="flex-1 bg-white flex flex-col justify-between hover:bg-gray-100">
                        <div className="p-6">
                          <CardHeader hit={hit} />
                          <a href={`/blog/${hit.slug}`}>
                              <CardBody hit={hit} />
                              {hit.author ? <CardFooter hit={hit}/> : ''}
                          </a>
                        </div>
                    </div>
                </div>
              ))}
          </div>
        </div>
  );
}

export default ThreeColumnCards;
