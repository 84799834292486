import { useState } from 'react';
import { Tabs, useTabState, Panel } from '@monorepo-nx/std-watch/ui'
import { HorizontalCards, Loader } from '@monorepo-nx/std-watch/ui';
import { useQuery } from '@apollo/client';
import { GET_POSTS_BY_AUTHOR } from '@monorepo-nx/std-watch/services';

const cn = (...args) => args.filter(Boolean).join(' ')

const Tab = ({ children }) => {
    const { isActive, onClick } = useTabState()

    return (
        <button className={cn('tab', isActive && ' border-indigo-500 text-indigo-600', 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
            'whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm')}
            onClick={onClick}>
            {children}
        </button>
    )
}

const SimpleTab = ({ author }) => {
    
    const [authorPosts, setAuthorPosts] = useState([]);
    const [pageInfo, setPageInfo] = useState([]);
    const { loading, error, data, fetchMore } = useQuery(GET_POSTS_BY_AUTHOR, {
        variables: {
            offset: 0,
            limit: 10,
            slug: author.slug
        },
            
        onCompleted: (data) => {
            if (!authorPosts.length) {
                const results = data.postsConnection.edges;
                setAuthorPosts(results);
                setPageInfo(data.postsConnection.pageInfo);
            }
        }
        
    });
 
    if (loading) return <Loader />;
    if (error) return <p>Error :(</p>;
   
    const loadMore = async () => {
    
        const {data} = await fetchMore({
            variables: {
                offset: authorPosts.length,
            },
        });
        const results = data.postsConnection.edges;
        setAuthorPosts((prevAuthorPosts) => [...prevAuthorPosts, ...results]);
        setPageInfo(data.postsConnection.pageInfo);
    }

    return (
        <Tabs>
            <label htmlFor="tabs" className="sr-only">
                Select a tab
            </label>
            <div className="sm:px-2 sm:block">
                <div className="  border-gray-200">
                    <div className="-mb-px px-2 flex space-x-6 " aria-label="Tabs">
                        <Tab>All Articles</Tab>
                        <Tab>About</Tab>
                    </div>
                    <div className='tab-progress' />
                    <Panel>
                        <div  >
                        {authorPosts.map((post, id ) => (
                                <HorizontalCards key={id} post={post.node} />
                            ))}
                           { pageInfo.hasNextPage ?
                            <div className="text-center mt-16 mb-16" >
                            <button  onClick={loadMore}
                            className="inline-flex items-center rounded border border-transparent bg-indigo-100 px-10 py-1.5 text-xs sm:text-sm font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                Load More
                            </button></div> : <p></p>}
                        </div>
                    </Panel>
                    <Panel>
                        <p className=" h-screen p-4 text-gray-500 text-start text-sm ">{author.biography ? author.biography : " "}</p>
                    </Panel>

                </div>
            </div>
        </Tabs>
    )
}

export default SimpleTab;