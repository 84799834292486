import { AppContext } from '@monorepo-nx/std-watch/services';
import { useRouter } from "next/router";
import Link from "next/link";
import { Popover } from '@headlessui/react';
import clsx from 'clsx';
import { useContext } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import {NavigationLink} from '@monorepo-nx/std-watch/ui';



function MenuIcon({ open, ...props }) {
  return (
    <svg
      aria-hidden="true"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d={open ? 'M17 7 7 17M7 7l10 10' : 'm15 16-3 3-3-3M15 8l-3-3-3 3'}
      />
    </svg>
  );
}

export const SubMenu = () => {

  const slideLeft = () => {
    var slider = document.getElementById('slider');
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideRight = () => {
    var slider = document.getElementById('slider');
    slider.scrollLeft = slider.scrollLeft + 500;
  };
  const { categoryPageData } = useContext(AppContext);
  
  const menuData = [...categoryPageData?.subCategories || []]
  const categorySlug = categoryPageData?.category?.title.toLowerCase().replace(/ /g, '-');


  const router = useRouter();
  

  return (

    <>
      {/* Mobile */}
      <div className="sticky top-0 z-40 ">
        <Popover className="md:hidden ">
          {({ open }) => (
            <>
              <div
                className={clsx(
                  'relative flex items-center py-3 px-4'
                )}
              >
                {!open && (
                  <span className="text-lg mx-4">
                    {categoryPageData?.category?.title}
                    {menuData.map((item, index) => (

                      <span key={index} className=" text-sm text-indigo-600"> {router.asPath.includes(`/${item.slug}`) && `/ ${item.title}`}
                      </span>
                    ))}
                  </span>
                )}
                <Popover.Button
                  className={clsx(
                    'ml-auto flex h-8 w-8 items-center justify-center focus:border focus:border-transparent',
                    open && 'relative z-10 border border-transparent'
                  )}
                  aria-label="Toggle navigation menu"
                >
                  {!open && (
                    <>
                      {/* Increase hit area */}
                      <span className="absolute inset-0 " />
                    </>
                  )}
                  <MenuIcon open={open} className="h-7 w-7 stroke-gray-900  "/>
                </Popover.Button>
              </div>
              <Popover.Panel className="absolute inset-x-0 top-0  bg-white/95 py-3.5 shadow-sm [@supports(backdrop-filter:blur(0))]:bg-white/80 [@supports(backdrop-filter:blur(0))]:backdrop-blur">
                {menuData.map((item, index) => (
                  <Popover.Button
                    as={Link}
                    key={index}
                    href={`/${categorySlug}/${item?.slug}`}
                    className="flex items-center py-1.5"
                  >
                    <span className="mx-10  text-base font-medium text-slate-900">
                      {item.title}
                    </span>

                  </Popover.Button>
                ))}

              </Popover.Panel>
            </>
          )}
        </Popover>

        {/* Desktop */}
        <div className="hidden md:block mx-auto md:max-w-8xl md:px-44 mb-2 ">
          {/* <p className='text-xl text-indigo-600'>{categoryPageData?.category?.title}</p> */}

          <div className="md:flex md:justify-center ">


            <div className='h-6 w-6 mt-2  mr-4 flex-none'>
              <ChevronLeftIcon className='opacity-60 cursor-pointer hover:opacity-90' onClick={slideLeft} />
            </div>

            <ul
              id='slider'
              className="grid grid-flow-col  overflow-x-scroll scroll whitespace-nowrap scroll-smooth no-scrollbar"
            >
              {menuData.map((item, index) => (
                <li key={index}
                className={clsx(
                  'flex w-full flex-col text-md text-center text-gray-600 lg:text-md  items-center justify-center  px-3 py-2 ',
                  router.asPath.includes(`/${item.slug.split('/').pop()}`)
                  ? '  text-gray-900 font-semibold before:text-gray-600'
                  : ' before:text-gray-600  hover:before:text-gray-600'
                )}>
                  <NavigationLink href={`/${categorySlug}/${item?.slug}`} isActive={router.asPath.includes(`/${item.slug}`)}>
                    {item.title}
                    </NavigationLink>
                  {/* <Link
                    href={`/${categorySlug}/${item?.slug}`}
                    className={clsx(
                      'flex w-full flex-col text-md text-center lg:text-md  items-center justify-center border-b ',
                      router.asPath.includes(`/${item.slug}`)
                        ? 'border-gray-800  text-indigo-900 font-semibold  before:text-gray-500'
                        : 'border-transparent before:text-gray-900  hover:border-gray-800 hover:before:text-gray-500'
                    )}
                  >
                    {item.title}
                  </Link> */}
                </li>
              ))}

            </ul>
            <div className='h-6 w-6  ml-2 mt-2 flex-none'>
              <ChevronRightIcon className='opacity-60  cursor-pointer hover:opacity-90' onClick={slideRight} />
            </div>
          </div>
        </div>
      </div>
    </>
  );

}
export default SubMenu;
