import ReactMarkdown from 'react-markdown'
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import { SendEvent } from '@monorepo-nx/std-watch/services';
import AffiliateLinkContainer from '../affiliate-link/affiliate-link-container';


const handleClick = (e, linkText, href, section) => {
  SendEvent({eventName: 'click-component', component: 'faqs-accordion-component', href, section, text: linkText})
}

function LinkRenderer(props) {
  if ((props.href.includes('/referral'))) {    
    let affName = props.href.split('/referral/').pop().split('/')[0] + '/' + props.href.split('/referral/').pop().split('/')[1]
    return <AffiliateLinkContainer affName={affName} href={props.href}> {props.children} </AffiliateLinkContainer>
  }
  return (
    <a href={props.href} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  )
}

export function Faq({question, answer, href, topic}) {
  return (
    <Disclosure as="div" key={question}>
      {({ open }) => (
        <>
          <dt onClick={(e) => handleClick(e, question, href, topic)}>
            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900" >
              <span className="text-base font-light leading-7">{question}</span>
              <span className="ml-6 flex h-7 items-center">
                {open ? (
                  <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                ) : (
                  <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                )}
              </span>
            </Disclosure.Button>
          </dt>
          <Disclosure.Panel as="dd" className="mt-2 pr-12">
            <ReactMarkdown components={{ a: LinkRenderer}} className="text-base leading-7 text-gray-600">{answer}</ReactMarkdown>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
export default Faq;
