import Link from "next/link";
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid'

function Pagination({ totalPostCount, pageSize=19, currentPage, prefix }) {
  if (totalPostCount === 0) {
    return null
  }
  let pageIntoArray = Array.from(Array(Math.ceil(totalPostCount / pageSize))).map((_, idx) => idx)
  
  currentPage = parseInt(currentPage, 10)
  const nextClickablePages = 3;
  const nextClickablePagesMobile = 1;

  const paginationUrl = (nextPage) => {
    return {pathname: prefix, query:{page: nextPage}}
  }
  const lastPage = pageIntoArray[pageIntoArray.length - 1] + 1
  return (
    <nav className="flex items-center justify-center border-gray-200 px-4 mt-4 sm:px-0 mb-10">
      {/* Previous Page */}
      <div className="-mt-px flex  justify-end">
        {currentPage === 1 ? '' :
          <Link arial-label={`Previous Page`} href={paginationUrl(currentPage-1)} className="inline-flex items-center border-b-2 px-4 pt-4 text-sm font-medium border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" >
            <ArrowLongLeftIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          </Link>}
      </div>
      {/* First page */}
      <div className=" md:-mt-px md:flex">
        <Link href={paginationUrl(1)} aria-label={`Page 1`}
          className={`inline-flex items-center border-b-2 px-4 pt-4 text-sm font-medium ${currentPage === 1 ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}`}>
          1</Link>
      </div>
      {currentPage > nextClickablePages + 1 ? (
        <span className=" inline-flex items-center border-b-2 border-transparent  pt-4 text-sm font-medium text-gray-500">
          ...
        </span>
      ) : ''}
      {/* previous pages numbers  */}
      {pageIntoArray.slice(Math.max(1, currentPage - nextClickablePages - 1), Math.min(currentPage + nextClickablePages, lastPage - 1)).map(page => {
        let flagCurrent = currentPage === page + 1 ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
        return <div className="hidden sm:-mt-px sm:flex" key={page}>
          <Link aria-label={`Page ${page+1} of ${lastPage}`} href={paginationUrl(page+1)} className={`inline-flex items-center border-b-2 px-4 pt-4 text-sm font-medium ${flagCurrent}`}>
            {page + 1}
          </Link>
        </div>
      })}
      {/* next pages numbers  */}
      {pageIntoArray.slice(Math.max(1, currentPage - nextClickablePagesMobile - 1), Math.min(currentPage + nextClickablePagesMobile, lastPage - 1)).map(page => {
        let flagCurrent = currentPage === page + 1 ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
        return <div className="sm:hidden " key={page}>
          <Link aria-label={`Page ${page+1}} of ${lastPage}` } href={paginationUrl(page+1)} className={`inline-flex items-center border-b-2 px-4 pt-4 text-sm font-medium ${flagCurrent}`}>
            {page + 1}
          </Link>
        </div>
      })}
      {currentPage < lastPage - nextClickablePages - 1 ? (
        <span className="inline-flex items-center border-b-2 border-transparent md:px-4 pt-4 text-sm font-medium text-gray-500">
          ...
        </span>
      ) : ''}
      <div className="-mt-px flex  ">
        <Link href={paginationUrl(lastPage)} className={`inline-flex items-center border-b-2 px-4 pt-4 text-sm font-medium ${currentPage === lastPage && currentPage !== 1 ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}`} >
          {(lastPage !== 1) && lastPage}
        </Link>
      </div>
      {/* Next Page */}
      {currentPage === pageIntoArray[pageIntoArray.length - 1] + 1 ? '' :
        <div className="-mt-px flex  ">
          <Link aria-label={"Next Page"} href={paginationUrl(currentPage+1)} className="inline-flex items-center border-b-2 px-4 pt-4 text-sm font-medium border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" >
            <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          </Link>
        </div>}
    </nav>
  )
}

export default Pagination