import { Fragment, useState } from 'react'
import { useRouter } from 'next/router'
import { Combobox, Dialog, Transition, Popover } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { SearchNav } from '@monorepo-nx/std-watch/ui'
import { SendEvent } from '@monorepo-nx/std-watch/services'




function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function SearchPalette(props) {

  const router = useRouter();

  const [query, setQuery] = useState('')

  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  const filteredProjects =
    query === ''
      ? []
      : projects.filter((project) => {
        return project.name.toLowerCase().includes(query.toLowerCase())
      })

  return (
    <div className=" lg:m-auto m-3 w-full cursor-pointer">
      <div type="button "
        onClick={() => {
          setIsOpen(true);
          SendEvent({ eventName: 'click-component', component: 'search', text: 'search', href: router.asPath, section: 'navbar' })
        }}
        className="flex items-center sx:mr-4 text-left space-x-4 xs:pr-24 pr-10 pl-4  h-9 bg-white ring-1 ring-gray-500   shadow-sm rounded-lg text-slate-400">
      <MagnifyingGlassIcon className=" text-left  text-gray-400 h-4 w-4" aria-hidden="true" />
        <span className="font-extralight"> Search...</span>
      </div>
      <Transition.Root show={isOpen} as={Fragment} afterLeave={() => setQuery('')} appear>
        <Dialog as="div" className="relative z-30" onClose={setIsOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed mt-24  inset-0 z-30 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
                <Dialog.Panel className="mx-auto mt-20 sm:mt-24  max-w-xl rounded-md   bg-gray-100 shadow-2xl">
                  <SearchNav className=" bg-red-100" close={closeModal} pagination={false} />
                </Dialog.Panel>
             
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}
export default SearchPalette;
