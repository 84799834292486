import { StarIcon } from '@heroicons/react/20/solid'

export default function Rating({ score = 10, size = 6, children, ...props }) {
    return (
        <div className="flex flex-row items-center">
            <div className="flex gap-x-1 text-yellow-300 text-center justify-center">
                {Array.from({ length: score / 2 }, (_, i) => (
                    <StarIcon key={`${i}`} className={`h-${size} w-${size} flex-none`} aria-hidden="true" />
                ))}
            </div>
            {children}
        </div>
    )
}
