import { ChartSingleRow } from '@monorepo-nx/std-watch/ui';

export function TopProviderSection({ promotion, siteConfig, callToAction }) {
  return (
    <div className="bg-[#354276] ">
      { promotion ? (
        <div className="mx-auto max-w-6xl px-6 py-16 md:px-32 ">
          <p className='text-white text-3xl text-center mb-12'>Our Best Choice</p>
            <ChartSingleRow promotion={promotion} callToAction={callToAction} colorPalette={siteConfig?.colorPalette} domain={siteConfig?.domain} className="mx-auto max-w-8xl" />
        </div>  
      ) : '' }
    </div>
  );
}
export default TopProviderSection;
