import { useRouter } from "next/router";
import Link from "next/link";
import clsx from 'clsx';

export function SubcategoryButtons({categoryPage}) {

  const router = useRouter();

  const categorySlug = categoryPage?.category?.title.toLowerCase().replace(/ /g, '-');
 
  return (
    <div className="text-center my-8 sm:my-6 mx-auto ">
      {categoryPage && categoryPage.subCategories?.map((subcategory) => (
        <Link    
          key={`/${categorySlug}/${subcategory?.slug}`}
          href={`/${categorySlug}/${subcategory?.slug}`}
          className={clsx(
            "inline-flex  justify-between m-1 items-center rounded-full  px-2.5 py-0.5 text-sm text-cyan-800 font-medium bg-cyan-100/50 hover:scale-110 ease-in-out duration-100",
            router.asPath.includes(subcategory?.slug)
            && 'ring-2 outline-none ring-offset-2 ring-cyan-200 ')}
        >
          {subcategory.title}
        </Link>
      ))}

    </div>
  );
}

export default SubcategoryButtons;



