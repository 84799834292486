import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {XMarkIcon} from '@heroicons/react/24/outline'
export const DisclosureText = ({text}) => (
  <p className="text-xs text-gray-500">
    {text}
  </p>
)
export function Disclosure(props) {
  const [open, setOpen] = useState(false)
  // TODO: move this to GraphCMS
 const disclosure = `"STDWatch.com is a free online resource that aims to provide helpful information to our  visitors, to ease the process of choosing a product or service that suits their needs. 

 We are able to offer this website service to you free of charge thanks to the advertising  fees we receive from the providers of the services and products we review, rank and  rate on this website. This means that  we receive consideration whenever one of our users decides to click on the provider’s  link on our website, or otherwise connect, sign up or purchase a service or product from  such providers.  
 
 While we strive to provide consumers with accurate and helpful information, note that  the advertising fees received from these companies will affect the offers’  positioning, rating & scoring (in the event assigned by us to such  companies). 
 
 The listing of such companies or providers on our website does not imply endorsement  of any such companies or their products. Our reviews are based solely on our team’s  subjective opinion.  
 
 All information, including pricing, posted on our website is subject to change at any  time on behalf of the applicable provider. We do not include all providers, brands,  products and services available in the market. 
 
 All representations and warranties regarding the information presented on this website  are disclaimed, see our Terms of Use for more information."`
  return (
    <>
    <div className=" lg:flex justify-center mx-auto px-3 py-1 font-light text-xs  text-gray-600" onClick={() => setOpen(true)}> We receive compensation from the providers of the services and products featured on this website. This impacts the offers’ positioning, rating & scoring. <span className="underline underline-offset-1">Advertising Disclosure</span></div>
    <hr className="container mx-auto"></hr>
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-30 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div>
                <div className="text-right">
                      <button
                        type="button"
                        className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setOpen(false)}>
                        <XMarkIcon className=" h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="p" className="text-sm  font-sm text-gray-900">
                     Advertising Disclosure
                    </Dialog.Title>
                    <div className="mt-2">
                      <DisclosureText text={disclosure} />
                      </div>
                  </div>
                </div>
              
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  
  </>
  )
}
// export default Disclosure;
