export default {
    titleTemplate: '%s | STDWatch.com',
    defaultTitle: "STDWatch.com",
    openGraph: {
      type: 'website',
      locale: 'en_US',
      url: 'https://www.stdwatch.com/',
      siteName: 'STDWatch.com',
      // description: "Default description should be added"
    },
    // twitter: {
    //   handle: '@handle',
    //   site: '@site',
    //   cardType: 'summary_large_image',
    // },
  };