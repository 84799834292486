import { grpahCMSImageLoader } from "@monorepo-nx/std-watch/services";
import Image from "next/image";
import Link from "next/link";


export function AuthorsTeam({ authors }) {
 
  return (
    <div className="cursor-pointer md:hover:scale-105 ease-in-out duration-300">
      <Link
        href="/medical-team"
        className="text-md font-light text-gray-600 hover:text-black "
      >
        <div className="my-auto text-center mb-4 lg:mb-4 ">
          Authors of Articles
        </div>
        <div className=" flex justify-center ">
          {authors.map((author, index) => (
            <Image
              key={index}
              className="  inline-block  rounded-full ring-2 ring-white "
              loader={grpahCMSImageLoader}
              width="40"
              height="40"
              src={author.node.picture ? author.node.picture.url : 'https://media.graphassets.com/8zBts3nFTpqLfsfvuj0T'}
              priority={false}
              alt="author"
            />))}
        </div>
      </Link>
      </div>
  );
}
export default AuthorsTeam;
