import { AppContext } from '@monorepo-nx/std-watch/services';
import Link from 'next/link';
import { useContext } from 'react'

const quickLinks = [
  { title: 'CCPA Notice', url: '/compliance/ccpa' },
  { title: 'Advertising Disclosure', url: 'https://www.stdwatch.com/advertising' },
  { title: 'Privacy Policy', url: '/compliance/privacy' },
  { title: 'Terms of Use', url: '/compliance/terms' },
  { title: 'How We Compare', url: 'https://www.stdwatch.com/how-we-compare-and-rate' },
];

const medicalDisclaimer = {
  title: "The information provided herein does not constitute an expert or medical advice, nor intended to replace such advice.",
  url: 'https://www.stdwatch.com/terms'
};

export function Footer(props) {

  const { footerData } = useContext(AppContext);
  const footer = [...footerData || []]
  
  return (
    <footer className="bg-[#354276]  p-6 sm:p-8" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-8xl  sm:px-6 sm:py-4 md:px-8">
        <div className="mt-12 mb-2 grid md:grid-cols-3 grid-cols-2 gap-8  xl:mt-0">

         {footer.map((item, id) => (
          <div key={id}>
            <h3 className="text-base font-medium text-indigo-300">{item.NavMenuDropdown_title_alias}</h3>
            <ul className="mt-4 space-y-3 ">
              {item.NavMenuDropdown_links_alias.map((i, id) => (
                <li key={id} >
                  <Link href={i.url} className="text-sm text-gray-300 hover:text-gray-500">
                    {i.title}
                  </Link>
                </li>
              ))}
            </ul> 
          </div>
         ))}

         {/* Mobile */}
          <div className="md:hidden grid">
            <h3 className="text-base  md:mt-0 font-medium text-indigo-300">Quick Links</h3>
            {quickLinks.map((item, id) => (
              <Link key={id} href={item.url} className="text-sm md:text-center text-gray-300 hover:text-gray-500">
                {item.title}
              </Link>
            ))}
          </div>

          <div >
            <h3 className="text-base  md:mt-0 font-medium text-indigo-300">Medical Disclaimer</h3>
            <div className="my-4  ">
              <Link href={medicalDisclaimer.url} className="lg:text-base text-sm text-gray-300 hover:text-gray-500">
                {medicalDisclaimer.title}
              </Link>
            </div>
          </div>

        </div>
      </div>
      
      {/* Desktop */}
      <div className=" border-t border-[#7278c7] ">
        <div className="hidden mx-auto max-w-8xl mt-4 md:mt-1 md:grid md:grid-cols-5">
          {quickLinks.map((item, id) => (
            <Link key={id} href={item.url} className="text-sm md:text-center text-gray-300 hover:text-gray-500">
              {item.title}
            </Link>
          ))}
        </div>
        <p className=" my-8 text-base text-gray-300 md:text-center">&copy; {(new Date().getFullYear())} STDWatch - All rights reserved.</p>
      </div>
    </footer>
  );
}
export default Footer;
