import React, { useState, useMemo } from 'react';
import algoliasearch from 'algoliasearch/lite';
import Link from 'next/link';
import {
  InstantSearch,
  SearchBox,
  Pagination,
  Configure,
  Hits,
} from 'react-instantsearch';
import { getSession } from '@monorepo-nx/std-watch/services';


interface AlgoliaConfigProps {
  searchParameters: any;
  searchBox: boolean;
  pagination: boolean;
  // hitsElement: any;
  close: any;
  appId: string;
  apiKey: string;
  indexName: string;
  initialSearchState: { query: string };
  showSearchBar: boolean;
  hitsPerPage: number;
}

export const WrapWithHits = (searchInstance: AlgoliaConfigProps) => {
  const searchClient = useMemo(() => {
    return algoliasearch(searchInstance.appId, searchInstance.apiKey);
  }, [searchInstance.appId, searchInstance.apiKey]);

  // const hits = searchInstance.hitsElement;

  const [searchState, setSearchState] = useState(
    searchInstance.initialSearchState
  );

  const setNextSearchState = ({ uiState, setUiState }) => {
    setUiState(uiState);
  };
  function Hit({ hit }) {
    // "html"
    // "content"
    // "headings"
    // "show_advertising_disclosure"
    // "title"
    // "publish"
    // "meta_description"
    // "image"
    // "tags"
    // "categories"
    // "date"
    // "excerpt_html"
    // "excerpt_text"
    // "slug"
    // "type"
    // "url"
    // "objectID"
    // "_snippetResult"
    // "_highlightResult"
    // "__position"
    // "__queryID"
    console.debug(JSON.stringify(Object.keys(hit)));
    const snippetResult = hit['_snippetResult']?.content.value
    const excerpt = hit['excerpt']
    const snippet = snippetResult ? snippetResult : excerpt

    return <li className="pr-6">
      <div className="relative max-w-18xl mx-auto">
        <div className="transition duration-700 mx-4 prose prose-a:text-indigo-500 ">
          <Link onClick={() => searchInstance.close()} href={`/blog/${hit['slug']}`}>{`${hit['title']}`}</Link>
        </div>
        <p className="mx-4 mb-4 text-sm">
          {snippet}
        </p>
      </div>
    </li>
  }
  function transformItems(items, metadata) {
    return items
  }
  const userId = getSession()?.userId || '';
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={searchInstance.indexName}
      initialUiState={{ [searchInstance.indexName]: searchState }}
      insights={true}
      onStateChange={setNextSearchState}
    >
      <Configure
        clickAnalytics
        userToken={userId}
        {...{
          hitsPerPage: searchInstance.hitsPerPage,
          ...searchInstance.searchParameters,
        }}
      />
      <div>
        <div
          className="hit-actions"
          style={
            searchInstance.showSearchBar
              ? { display: 'block' }
              : { display: 'none' }
          }
        >
          {searchInstance.searchBox ? (
            <div>
              <SearchBox
                autoFocus
                className=''
                searchAsYouType={false}
                // reset={<img src=" " alt="" />}
                // submit={<img src=" " alt="" />}

                translations={{
                  // placeholder: 'Type a question or keyword',
                }}
              />
              <Hits hitComponent={Hit} transformItems={transformItems} />
            </div>
          ) : null}
        </div>

        {/* <div>{searchState.query !== '' ? hits : ''}</div> */}
        {searchState.query !== '' ? (
          <div className="hit-pagination pb-8">
            {searchInstance.pagination ? (
              <Pagination showLast={true} padding={2} />
            ) : null}
          </div>
        ) : (
          ''
        )}
      </div>
    </InstantSearch>
  );
};

// defaultProps added so that they're displayed in the JSX addon
WrapWithHits.defaultProps = {
  appId: process.env['NEXT_PUBLIC_ALGOLIA_APP_ID'],
  apiKey: process.env['NEXT_PUBLIC_ALGOLIA_API_KEY'],
  indexName: process.env['NEXT_PUBLIC_ALGOLIA_INDEX_NAME'],
  searchBox: true,
  pagination: true,
  hitsPerPage: 6,
};

interface SearchProps {
  initialSearchState: { query: string };
  showSearchBar: boolean;
  limit?: number;
  pagination: boolean;
  title: string;
  image: string;
  slug: string;
  author: { name: string; picture: { url: string } };
  excerpt: string;
  date: Date;
  close?: any;
}

export const SearchNav = (config: AlgoliaConfigProps) => {
  // const searchConfigProps: AlgoliaConfigProps = {
  //   searchParameters: {},
  //   ...search,
  // };

  return (
    <div>
      <WrapWithHits {...config} ></WrapWithHits>
    </div >
  );
};

SearchNav.defaultProps = {
  initialSearchState: { query: '' },
  showSearchBar: true,
  limit: 10,
  pagination: true,
};

export default SearchNav;
